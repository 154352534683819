<template>
  <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <select v-show="!component_to_label" v-bind:id="component_meta.variable" v-bind:name="component_meta.variable"
    class="form-control" v-model="component_value" @change="change_value($event)"
    v-bind:class="{ 'is-invalid': component_message != '' && component_message != undefined }">
    <template v-for="(item, key) in component_select" :key="key">
      <option v-bind:value="key">
        {{ item.title }}
      </option>
    </template>
  </select>
  <div class="other_tip" v-show="is_select_other && !component_to_label">{{ other_tip }}</div>
  <input type="text" v-show="is_select_other && !component_to_label" v-bind:id="component_meta.variable + '_other'"
    v-bind:name="component_meta.variable + '_other'" v-model="component_other_value" class="form-control"
    @change="change_other_value($event)">
  <div class="invalid-mssage" v-show="component_message != ''">{{ component_message }}</div>
  <div v-show="component_to_label">
    {{ component_value }}
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    let component_select = {};

    // 載入組件清單給下拉元件
    if (this.field.component.properties.source_json != '' && this.field.component.properties.source_json != undefined) {
      component_select = JSON.parse(this.field.component.properties.source_json);
      for (let key in component_select) {
        component_select[key] = { 'title': component_select[key] };
      }
    }
    else {

      let parameters = '';
      let params = {};

      if (this.field.component.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < this.field.component.properties.sort_rule_fields.length; i++) {
          let variable = this.field.component.properties.sort_rule_fields[i].field_variable;
          let rule = this.field.component.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      if (this.field.component.properties.datazone != '' && this.field.component.properties.datazone != undefined) {
        params['dz'] = this.field.component.properties.datazone;
      }

      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }


      let api_url = this.$microlink.base_api_url + this.field.component.properties.source_api + parameters;

      // 如果有設定快取，則從快取中取得資料
      if (this.field.component.properties.pool == 'Y') {

        let pool_key = this.$microlink.base64_encode(api_url);
        if (this.$microlink.get_data_pool(pool_key) == undefined) {
          component_select = this.$microlink.api(api_url);
          component_select = component_select.data;
          this.$microlink.set_data_pool(pool_key, component_select);
        }
        else {
          component_select = this.$microlink.get_data_pool(pool_key);
        }
      }
      else {
        component_select = this.$microlink.api(api_url);
        component_select = component_select.data;
      }
    }

    // 如有開啟「其它」選項，則加入其它選項
    if (this.field.component.properties.other == 'Y') {

      let other_text = '其它';
      // 如果有設定其它選項的標題，則使用設定的標題
      if (this.field.component.properties.other_text != undefined && this.field.component.properties.other_text != '') {
        other_text = this.field.component.properties.other_text;
      }

      component_select['other'] = { 'title': other_text };
    }


    component_select = { '': { 'title': '請選擇' }, ...component_select };
    let localValue = (this.value == undefined || this.value == '') ? this.field.component.properties.default : this.value;
    let localMeta = this.field;
    let is_select_other = false;
    let component_other_value = '';

    // 如果此元件被標籤化的話，那就不顯示元件，只顯示元件值
    if (localMeta.component.properties.to_label == 'Y') {
      if (component_select[localValue] != undefined) {
        localValue = component_select[localValue].title;
      }
    }
    // 如果元件值是其它選項，則顯示其它選項的值

    else if (component_select[localValue] == undefined) {
      is_select_other = true;
      component_other_value = localValue;
      localValue = 'other';
    }
    else if (localValue == 'other') {
      is_select_other = true;
      component_other_value = '';
    }

    return {
      // 元件識別碼
      component_id: 'select-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: localMeta,

      // 元件值
      component_value: localValue,

      // 元件選項
      component_select: component_select,

      // 元件訊息
      component_message: this.message,

      // 元件是否被標籤化
      component_to_label: (this.field.component.properties.to_label == 'Y') ? true : false,

      // 其它選項的值
      component_other_value: component_other_value,

      // 是否為其它選項
      is_select_other: is_select_other,

      // 其它選項的提示文字
      other_tip: this.field.component.properties.other_placeholder
    }
  },
  mounted(){
    let $event = {'target' : {'value' : this.component_value}};
    this.$emit('input', $event);
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {
    change_value: function ($event) {

      // 驗證
      this.validate($event.target.value);

      if ($event.target.value == 'other') {
        this.is_select_other = true;
      }
      else {
        this.is_select_other = false;
      }

      this.component_value = $event.target.value;
      this.$emit('input', $event);
    },

    change_other_value: function ($event) {
      // 驗證
      this.validate($event.target.value);

      this.component_other_value = $event.target.value;
      this.$emit('input', $event);
    },

    validate: function (value) {

      this.component_message = '';

      // 是否必填
      if (this.component_meta.component.properties.required == 'Y' && value == '') {
        this.component_message = '必填';
      }

      // 如有開啟「其它」選項，則驗證是否有輸入其它選項的值
      if (this.field.component.properties.other == 'Y' &&
        (this.component_value == 'other' && this.component_other_value == '' || this.component_value == 'other' && this.component_other_value == undefined)) {
        let other_text = '其它';
        // 如果有設定其它選項的標題，則使用設定的標題
        if (this.field.component.properties.other_text != undefined && this.field.component.properties.other_text != '') {
          other_text = this.field.component.properties.other_text;
        }
        this.component_message = '請填寫「' + other_text + '」資料';
      }

      return this.component_message;
    },
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

.other_tip {
  font-weight: bold;
  margin: 10px 0px;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
</style>